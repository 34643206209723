
import en from './locales/en-US.js'
import tw from './locales/zh-TW.js'

export default defineI18nConfig(() => ({
    legacy: false,
    fallbackLocale: 'tw',
    messages: {
        en: en,
        tw: tw,
    }
}))
